/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"

import "../styles/normalize.css"
import "../styles/style.css"

const Layout = ({ children }) => {
  return (
    <>
      <StaticImage
        src="../images/tirabg_d_s.png"
        placeholder="blurred"
        quality={100}
        formats={["AUTO", "WEBP"]}
        alt="Background"
        className="background-image"
      />
      {children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
