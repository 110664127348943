import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

export const IndexPage = () => {
  let data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "tira1_small.jpg" }) {
        childImageSharp {
          resize(width: 1200) {
            src
            height
            width
          }
        }
      }
    }
  `)
  const img = data.image ? data.image.childImageSharp.resize : null
  return (
    <Layout>
      <SEO
        image={img}
        title="This Is Real AI: 100 Real-World Implementations of Artificial Intelligence"
      />
      <Book />
    </Layout>
  )
}

export const Book = () => {
  const isServer = typeof window === "undefined"
  let width = isServer ? 0 : window.innerWidth
  let showDesktop = width >= 1000
  return (
    <main>
      <div className="wrapper-book">
        <div className="wrapper-book-inner">
          <div className="wrapper-book-text long-text">
            <h1>This Is Real AI</h1>
            <div className="header-separator">&#10022; &#10022; &#10022;</div>
            <p>
              Did you know? The world's best flu vaccine was recently discovered
              with artificial intelligence (AI). Progressive organizations are
              using AI to invent new food recipes, perfume scents, and alcoholic
              beverages. Children in more than 200 cities across China are being
              tutored by AI. In the US, AI has secured political victories for
              both Barack Obama and Donald Trump. News articles are being
              written by AI autonomously. Police authorities around the globe
              are using AI to track and identify suspects.
            </p>
            {(!showDesktop || width === 0) && (
              <StaticImage
                src="../images/tira1_small.jpg"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="The book This Is Real AI"
                placeholder="blurred"
                className="treat-like-p hidden-on-desktop"
              />
            )}
            <p>
              AI is being used to explore Mars, scan pizzas, automate
              warehouses, write music, pick apples, analyze phone conversations,
              remake video games, detect cancer, flip burgers, mimic world
              leaders, commit mass-surveillance, match job candidates, and find
              lost dogs.
            </p>
            <p>
              These are the stories that This Is Real AI is all about. It's a
              non-technical book that makes AI easy to understand. One hundred
              diverse real-world implementations of AI are explained short-form
              and to the point, without any technical lingo. The book gives you
              information of value within minutes.
            </p>
            {(!showDesktop || width === 0) && (
              <StaticImage
                src="../images/tira2_small.jpg"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="The book This Is Real AI"
                placeholder="blurred"
                className="treat-like-p hidden-on-desktop"
              />
            )}
            <p>
              There are examples of how AI is being used by both colossal
              multinational corporations and tiny new startups. There are
              stories from both technical and non-technical organizations;
              stories of mundane day-to-day uses and stories of exciting
              innovations; stories terrifying and exhilarating.
            </p>
            <p>
              There is no technology more crucial to understand today than AI.
              Yet while many are curious about it, they often feel lost. Some
              may feel as though AI is difficult to comprehend and certainly
              challenging to apply. With This Is Real AI, I aim to make
              artificial intelligence accessible to anyone, regardless of their
              technical level. Artificial intelligence must be made accessible,
              to democratize access to the technology.
            </p>
            {(!showDesktop || width === 0) && (
              <StaticImage
                src="../images/tira3_small.jpg"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="The book This Is Real AI"
                placeholder="blurred"
                className="treat-like-p hidden-on-desktop"
              />
            )}
            <p>
              Additionally, the book also features an analytical part. I walk
              readers' through a non-technical and easy-to-apply framework that
              explains how to create value with AI through either automation or
              augmentation, using one of four AI strategies: efficiency,
              effectiveness, expert, and innovation. Naturally, key topics such
              as machine learning, natural language processing, and deepfakes
              are covered in the book.
            </p>
            <p>This Is Real AI is not another book about the future.</p>
            <p>It's a book about the present.</p>
            {(!showDesktop || width === 0) && (
              <StaticImage
                src="../images/tira4_small.jpg"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="The book This Is Real AI"
                placeholder="blurred"
                className="treat-like-p hidden-on-desktop"
              />
            )}
            <h1 className="h1-book">Available on Amazon</h1>
            <p>
              This Is Real AI is available in softcover, hardcover and as a
              Kindle e-book! Find it on an Amazon storefront near you. Thanks!
            </p>
            {(!showDesktop || width === 0) && (
              <StaticImage
                src="../images/tira5_small.jpeg"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="The book This Is Real AI"
                placeholder="blurred"
                className="treat-like-p hidden-on-desktop"
              />
            )}
            <h2>North America</h2>
            <div className="joker border">
              <a
                href="https://amzn.to/333d4fm"
                className="no-select"
                target="_blank"
                rel="noreferrer"
              >
                The United States of America
              </a>
            </div>
            <div className="joker border">
              <a
                href="https://www.amazon.ca/dp/B089M1H3SJ"
                className="no-select"
                target="_blank"
                rel="noreferrer"
              >
                Canada
              </a>
            </div>
            <div className="joker border">
              <a
                href="https://www.amazon.com.mx/dp/B089NXW9HD"
                className="no-select"
                target="_blank"
                rel="noreferrer"
              >
                Mexico
              </a>
            </div>
            <h2>South America</h2>
            <div className="joker border">
              <a
                href="https://www.amazon.com.br/dp/B089NXW9HD"
                className="no-select"
                target="_blank"
                rel="noreferrer"
              >
                Brazil
              </a>
            </div>
            <h2>Europe</h2>
            <div className="joker border">
              <a
                href="https://amzn.to/3nNyzsE"
                className="no-select"
                target="_blank"
                rel="noreferrer"
              >
                The United Kingdom
              </a>
            </div>
            <div className="joker border">
              <a
                href="https://amzn.to/3pPwKNy"
                className="no-select"
                target="_blank"
                rel="noreferrer"
              >
                Germany
              </a>
            </div>
            <div className="joker border">
              <a
                href="https://amzn.to/3lTpcXD"
                className="no-select"
                target="_blank"
                rel="noreferrer"
              >
                France
              </a>
            </div>
            <div className="joker border">
              <a
                href="https://amzn.to/3fl44Y1"
                className="no-select"
                target="_blank"
                rel="noreferrer"
              >
                Sweden
              </a>
            </div>
            <div className="joker border">
              <a
                href="https://www.amazon.es/dp/B089M1H3SJ"
                className="no-select"
                target="_blank"
                rel="noreferrer"
              >
                Spain
              </a>
            </div>
            <div className="joker border">
              <a
                href="https://www.amazon.it/dp/B089M1H3SJ"
                className="no-select"
                target="_blank"
                rel="noreferrer"
              >
                Italy
              </a>
            </div>
            <div className="joker border">
              <a
                href="https://www.amazon.nl/dp/B089NXW9HD"
                className="no-select"
                target="_blank"
                rel="noreferrer"
              >
                The Netherlands
              </a>
            </div>
            <h2>Asia</h2>
            <div className="joker border">
              <a
                href="https://www.amazon.co.jp/dp/B089M1H3SJ"
                className="no-select"
                target="_blank"
                rel="noreferrer"
              >
                Japan
              </a>
            </div>
            <div className="joker border">
              <a
                href="https://www.amazon.in/dp/B089NXW9HD"
                className="no-select"
                target="_blank"
                rel="noreferrer"
              >
                India
              </a>
            </div>
            <h2>Oceania</h2>
            <div className="joker border">
              <a
                href="https://www.amazon.com.au/dp/B089NXW9HD"
                className="no-select"
                target="_blank"
                rel="noreferrer"
              >
                Australia
              </a>
            </div>
            <div className="about">
              <a
                href="https://jacobbergdahl.com/"
                className="no-select"
                target="_blank"
                rel="noreferrer"
              >
                Learn more about the author: Jacob Bergdahl
              </a>
              <a
                href="https://aiforelasning.se/en/"
                className="no-select"
                target="_blank"
                rel="noreferrer"
              >
                Learn more about Jacob's lectures
              </a>
            </div>
          </div>
          {(showDesktop || width === 0) && (
            <div className="wrapper-book-images">
              <div className="img-hover-zoom">
                <StaticImage
                  src="../images/tira1_small.jpg"
                  formats={["AUTO", "WEBP"]}
                  placeholder="blurred"
                  alt="The book This Is Real AI"
                />
              </div>
              <div className="img-hover-zoom">
                <StaticImage
                  src="../images/tira2_small.jpg"
                  formats={["AUTO", "WEBP"]}
                  placeholder="blurred"
                  alt="The book This Is Real AI"
                />
              </div>
              <div className="img-hover-zoom">
                <StaticImage
                  src="../images/tira3_small.jpg"
                  formats={["AUTO", "WEBP"]}
                  placeholder="blurred"
                  alt="The book This Is Real AI"
                />
              </div>
              <div className="img-hover-zoom">
                <StaticImage
                  src="../images/tira4_small.jpg"
                  formats={["AUTO", "WEBP"]}
                  placeholder="blurred"
                  alt="The book This Is Real AI"
                />
              </div>
              <div className="img-hover-zoom">
                <StaticImage
                  src="../images/tira5_small.jpeg"
                  formats={["AUTO", "WEBP"]}
                  placeholder="blurred"
                  alt="The book This Is Real AI"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  )
}

export const External = () => {
  return (
    <div className="external">
      <div className="wrapper-media">
        <div className="wrapper-media-inner">
          <h1>Learn more</h1>
          <div className="header-separator header-separator-top">
            &#10022; &#10022; &#10022;
          </div>
          <div className="wrapper-media-images">
            <a
              className="clickable-image"
              href="https://jacobbergdahl.com"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../images/jb.jpeg"
                formats={["AUTO", "WEBP"]}
                placeholder="blurred"
                alt="jacobbergdahl.com"
              />
              <div className="overlay-image">
                <div className="overlay-text">jacobbergdahl.com</div>
              </div>
            </a>
            <a
              className="img-description"
              href="https://jacobbergdahl.com"
              target="_blank"
              rel="noreferrer"
            >
              jacobbergdahl.com
            </a>
            <a
              className="clickable-image"
              href="https://thisisrealai.com"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../images/tiracom.png"
                formats={["AUTO", "WEBP"]}
                placeholder="blurred"
                alt="thisisrealai.com"
              />
              <div className="overlay-image">
                <div className="overlay-text">thisisrealai.com</div>
              </div>
            </a>
            <a
              className="img-description"
              href="https://thisisrealai.com"
              target="_blank"
              rel="noreferrer"
            >
              thisisrealai.com
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndexPage
